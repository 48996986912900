import type {LoaderDataset, AjaxModalResponse} from '/@/scripts/components/ajax-form'
import type {ApplicationParams} from '/@/scripts/components/app'
import type {AjaxFormResponse} from '/@/scripts/components/ajax-form'

import {Application, $, Factory} from "/@/scripts/components/app"
import {AjaxForm} from '/@/scripts/components/ajax-form'
import {YandexMap} from "/@/scripts/components/yandex-map"

import {Offcanvas, Modal, Carousel, Collapse} from 'bootstrap'
import IMask, {AnyMaskedOptions} from 'imask/esm/imask'
import {HttpRequest} from "/@/scripts/components/app/http"
import {LoadOverlay} from "/@/scripts/components/load-overlay"
import {tns} from "/node_modules/tiny-slider/src/tiny-slider"
import Analytics from "/@/scripts/components/analytics"
import Bvi from "/@/scripts/bvi/bvi"

const definitions = {
    'mask': function (element: HTMLElement, options: AnyMaskedOptions): IMask {
        return new IMask(element, options)
    },
    'modal': function (element: HTMLElement, options: Modal.Options): Modal {
        const modal = new Modal(element, options);

        $.on(element, 'show.bs.modal', () => {
            if (document.body.classList.contains('mode-offcanvas')) {
                offcanvas.hide()
            }
        })

        return modal
    },
    'async-modal': async function (this: Factory, element: HTMLElement): Promise<Modal> {
        const dataset = element.dataset as LoaderDataset

        return $.request(dataset.href).get().fetchJson()
            .then((json: AjaxModalResponse) => {
                let element = $.tag('div', json.data.content)
                element.classList.add('modal', 'fade')
                element.tabIndex = -1
                const modal = this.create(element, 'modal')
                $.on(element, 'hidden.bs.modal', () => {
                    element.remove()
                })
                document.body.append(element)

                return modal
            })
    },
    'ajax-form': function (this: Factory, element: HTMLFormElement): AjaxForm {
        this.discover(element)
        return new AjaxForm(element)
            .onPrepared(function (this: AjaxForm, data: FormData, request: HttpRequest) {
                request.withHeader(app.csrfHeader(), app.csrfToken())
                request.withUrlEncoded(data);
            })
            .onSuccess(function (this: AjaxForm, response: AjaxFormResponse) {
                const element = $.queryIn(this.element(), '.success-message')
                if (element && response.data.message) {
                    element.innerHTML = response.data.message
                    element.classList.remove('invisible')
                }
            })
    },
    'ajax-file-form': function (this: Factory, element: HTMLFormElement): AjaxForm {
        this.discover(element)
        return new AjaxForm(element)
            .onPrepared(function (this: AjaxForm, data: FormData, request: HttpRequest) {
                request.withHeader(app.csrfHeader(), app.csrfToken())
                request.withFormMultipartData(data);
            })
            .onSuccess(function (this: AjaxForm, response: AjaxFormResponse) {
                const element = $.queryIn(this.element(), '.success-message')
                if (element && response.data.message) {
                    element.innerHTML = response.data.message
                    element.classList.remove('invisible')
                }
            })
    },
    'yandex-map': function (this: Factory, element: HTMLElement): YandexMap {
        const params = app.params().map
        const coords = [params.map_lat, params.map_lon].map(coord => Number(coord));

        const loader = new LoadOverlay(element, true)

        return new YandexMap(element)
            .withMap({
                center: coords,
            })
            .onInit((map: YandexMap) => {
                loader.hide()
            })
    },
    'offcanvas-menu': function (this: Factory, element: HTMLElement): Offcanvas {
        const offcanvas = new Offcanvas(element)

        $.on(element, 'show.bs.offcanvas', function () {
            document.body.classList.add('mode-offcanvas')
        })

        $.on(element, 'hide.bs.offcanvas', function () {
            document.body.classList.remove('mode-offcanvas')
        })

        return offcanvas
    },
    'carousel': function (this: Factory, element: HTMLElement): Carousel {
        return new Carousel(element, {
            interval: 2000,
            pause: false
        })
    }
}

const app = new Application(window.AppParams as ApplicationParams, definitions)
const analytics = new Analytics(window.AppParams.analytics, [
    'app:form:submit',
    'app:form:success',
    'click',
])
const offcanvas = app.factory().create($.id('menu'), 'offcanvas-menu') as Offcanvas

document.addEventListener('app:form:init', (event) => {
    const form = event.target as HTMLFormElement

    if (form.matches('form')) {
        form.querySelectorAll("input[name*='ga_clientId']").forEach(input => {
            input.value = JSON.stringify(analytics.utm())
        })
    }
})

document.addEventListener('DOMContentLoaded', () => {

    new Bvi({
        target: '.bvi-switch-link',
        fontSize: 18,
        theme: 'white'
    });

    $.each('#menu', element => {
        app.factory().create(element, 'offcanvas-menu') as Offcanvas
    });

    $.each('#main-carousel', ((element) => {
        const carousel = new Carousel(element, {
            interval: 4000
        })

        $.eachIn(element, '.carousel-indicators button', (button) => {
            $.on(button, 'click', () => {
                carousel.to(button.dataset.bsSlideTo)
            })
        })
    }))

    $.each('.form-contacts', ((element) => {
        app.factory().create(element, 'ajax-form')
    }))

    $.each('.form-donor-sperm', ((element) => {
        app.factory().create(element, 'ajax-file-form')
    }))

    $.onIn(document, '[data-load]', 'click', function (event) {
        event.preventDefault()
        app.factory().create(this, 'async-modal')
            .then((modal: Modal) => {
                const form = modal._element.querySelector('form')
                if (event.target.dataset.appointmentType) {
                    app._appointmentType = event.target.dataset.appointmentType;
                }
                app.factory().create(form, 'ajax-form')
                $.eachIn(form, '[data-substitute="location"]', function (element: HTMLFormElement) {
                    element.value = window.location.href
                })
                modal.show()
            })
    })

    $.each('#license-carousel', ((element) => {
        const modalElement = $.id('license-modal') as HTMLElement
        const modal = app.factory().create(modalElement, 'modal')
        const modalCarouselElement = $.id('license-modal-carousel') as HTMLElement
        const modalCarousel = app.factory().create(modalCarouselElement, 'carousel')


        element.addEventListener('click', function (event) {
            event.preventDefault();
            const link = event.target.closest('.carousel-item');
            if (link) {
                $.eachIn(modalCarouselElement, 'img[data-src]', function (image:HTMLImageElement) {
                    image.src = image.dataset.src
                })
                modalCarousel.to(link.dataset.slideTo)
                modal.show()
            }
        });
    }))

    $.each('.collapse', function (element) {
        const collapsible = new Collapse(element, {
            toggle: false
        })

        element.addEventListener('show.bs.collapse', function () {
            element.parentElement?.classList.add('hs-show')
        })

        element.addEventListener('hide.bs.collapse', function () {
            element.parentElement?.classList.remove('hs-show')
        })
    })

    $.each('#slider-doctors', ((element) => {
        const slider = tns({
            container: '#slider-doctors',
            items: 1,
            nav: false,
            controlsContainer: '.tns-controls-arrows',
            // autoWidth: true,
            responsive: {
                768: {
                    items: 2
                },
                1200: {
                    items: 2
                }
            }
        });
    }))

    $.each('#slider-doctors-page', ((element) => {
        const slider = tns({
            container: '#slider-doctors-page',
            items: 1,
            nav: false,
            controlsContainer: '.tns-controls-arrows',
            autoHeight: true,
            responsive: {
                768: {
                    disable: true
                },
            }
        });
    }))

    $.each('#slider-reviews', ((element) => {
        const slider = tns({
            container: '#slider-reviews',
            items: 1,
            nav: false,
            controlsContainer: '.tns-controls-arrows-reviews',
            autoHeight: true,
            responsive: {
                768: {
                    items: 2,
                    gutter: 80
                },
                1200: {
                    items: 3
                }
            }
        });
    }))

    $.each('#map-contacts', (element => {
        const params = app.params().map
        const coords = [params.map_lat, params.map_lon].map(coord => Number(coord));

        app.factory().create(element, 'yandex-map')
            .withPlacemark({
                geometry: coords,
                properties: {
                    iconContent: params.address_street + ' ' + params.address_building,
                },
                options: {
                    preset: "islands#blueStretchyIcon",
                }
            })
            .render()
    }))

    $.each('#map-footer', (element => {
        const params = app.params().map
        const coords = [params.map_lat, params.map_lon].map(coord => Number(coord));

        app.factory().create(element, 'yandex-map')
            .withPlacemark({
                geometry: coords,
                properties: {}
            })
            .render()
    }))

    $.each('#header', ((element) => {
        const clone = element.cloneNode(true) as HTMLElement;
        const isHomePage = document.body.classList.contains('home')
        const homeStickPointElement = document.querySelector('.section-mission')
        const gap = 30;
        let stuck = false

        clone.classList.add('sticky')
        clone.querySelector('#menu').removeAttribute('id')
        
        document.body.prepend(clone)

        window.onscroll = function () {
            let distanceOffset = (isHomePage ? homeStickPointElement.offsetTop : element.offsetHeight) + gap
            let distance = element.offsetTop - window.pageYOffset
            let offset = window.pageYOffset

            if ((distance + distanceOffset <= 0) && !stuck) {
                clone.classList.add('sticky-visible')
                stuck = true
            } else if (stuck && (offset <= distanceOffset)) {
                clone.classList.remove('sticky-visible')
                stuck = false
            }
        }
    }))
})
